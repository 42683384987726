<section class="bg-black" style="padding-top: 20px">
  <div class="container pb-5">
    <div class="row">
      <div class="col-md-6 col-12">
        <h3>Bethnal Green</h3>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.294068166494!2d-0.06849068455966888!3d51.5261659796382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761cc7e03e8bcf%3A0x9ac130e44bd855e1!2sAli&#39;s%20Barber%20Shop!5e0!3m2!1sen!2suk!4v1588363461671!5m2!1sen!2suk"
          width="450"
          height="450"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
          class="rounded"
        ></iframe>
        <mat-card>
          <div class="row">
            <div class="col-12">
              <p>
                <span class="font-weight-bold">Address:</span> 258 Bethnal Green
                Rd, London E2 0AA
              </p>
              <p class="font-weight-bold mb-0">Hours:</p>
              <ul>
                <li>Monday: 10am–8pm</li>
                <li>Tuesday: 10am–8pm</li>
                <li>Wednesday: 10am–8pm</li>
                <li>Thursday: 10am–8pm</li>
                <li>Friday: 10am–8pm</li>
                <li>Saturday: 10am–8pm</li>
                <li>Sunday: 10am–8pm</li>
              </ul>
              <p><span class="font-weight-bold">Phone:</span> 020 7613 3873</p>
              <p class="mb-0">
                <i class="fa fa-train" aria-hidden="true"></i>
                <span class="font-weight-bold">
                  Bethnal Green Underground Station:</span
                >
                10 minute walk
              </p>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col-md-6 col-12">
        <h3 class="mt-lg-0 mt-3">Hainault</h3>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2478.042590179123!2d0.09214731528645927!3d51.60410821144937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a3fe01928823%3A0xe291da442fb9f04c!2sAli&#39;s%20Barber%20Shop!5e0!3m2!1sen!2suk!4v1588375552940!5m2!1sen!2suk"
          width="600"
          height="450"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
          class="rounded"
        ></iframe>
        <mat-card>
          <div class="row">
            <div class="col-12">
              <p>
                <span class="font-weight-bold">Address:</span> Ilford, IG6 3BD
              </p>
              <p class="font-weight-bold mb-0">Hours:</p>
              <ul>
                <li>Monday: 10am–8pm</li>
                <li>Tuesday: 10am–8pm</li>
                <li>Wednesday: 10am–8pm</li>
                <li>Thursday: 10am–8pm</li>
                <li>Friday: 10am–8pm</li>
                <li>Saturday: 10am–8pm</li>
                <li>Sunday: 10am–8pm</li>
              </ul>
              <p><span class="font-weight-bold">Phone:</span> 020 7613 3873</p>
              <p class="mb-0">
                <i class="fa fa-train" aria-hidden="true"></i>
                <span class="font-weight-bold">
                  Hainault Underground Station:</span
                >
                30 seconds walk
              </p>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</section>
