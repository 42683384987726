<div class="hero-section text-center">
  <div class="hero-overlay">
    <h1 class="page-title font-weight-bold">
      Look Your Best. Feel Your Best. Change The Game.
    </h1>
    <p>
      <i>
        "Good hair! Home to one of east London's busiest hair salons, Ali's
        Barber Shop at 258 Bethnal Green Road is the sharpest place to go if
        you're after a buzz cut." -
        <a
          href="https://www.theguardian.com/artanddesign/gallery/2010/jul/03/photography-exhibition-ali-barber-hair"
          target="_blank"
          class="text-white"
        >
          Click: The Guardian (2010)
        </a>
      </i>
    </p>
  </div>
</div>

<app-find-us-page></app-find-us-page>

<app-gallery-page></app-gallery-page>

<app-job-opening></app-job-opening>

<!-- Footer Section -->
<app-footer></app-footer>
