<div class="container text-center">
    <h2 class="font-weight-bold my-4">Booking Management</h2>

    <mat-card class="mt-2">
        <p class="text-muted"><span class="font-weight-bold">Important:</span> Please note that booking management
            is only accessible by the management and staff of Ali's Barber Shop</p>
        <form class="text-left">
            <div class="form-group">
                <label for="loginUsername" class="font-weight-bold">Username</label>
                <input type="text" class="form-control" id="loginUsername">
            </div>
            <div class="form-group">
                <label for="loginPassword" class="font-weight-bold">Password</label>
                <input type="password" class="form-control" id="loginPassword">
            </div>
            <button mat-flat-button type="submit" class="w-100 login-btn rounded text-white">
                Login <i class="fa fa-sign-in" aria-hidden="true"></i>
            </button>
        </form>

        <p class="text-muted mt-4"><span class="font-weight-bold">Issue logging in?</span> Please escalate with
            management as soon as possible!</p>
    </mat-card>


</div>