import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { HomePageComponent } from './home-page/home-page.component';
import { GalleryPageComponent } from './gallery-page/gallery-page.component';
import { BookingPageComponent } from './booking-page/booking-page.component';
import { FindUsPageComponent } from './find-us-page/find-us-page.component';
import { FollowUsPageComponent } from './follow-us-page/follow-us-page.component';
import { AlisApiService } from './services/alis-api.service';
import { FormsModule } from '@angular/forms';
import { BookingFormComponent } from './booking-form/booking-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MaterialModule } from './modules/material/material.module';
import { AuthModule } from './auth/auth.module';
import { ManagementViewBookingsPageComponent } from './management-view-bookings-page/management-view-bookings-page.component';
import { MasterPageComponent } from './pages/master-page/master-page.component';
import { FooterComponent } from './footer/footer.component';
import { JobOpeningComponent } from './job-opening/job-opening.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomePageComponent,
    GalleryPageComponent,
    BookingPageComponent,
    FindUsPageComponent,
    FollowUsPageComponent,
    BookingFormComponent,
    ManagementViewBookingsPageComponent,
    MasterPageComponent,
    FooterComponent,
    JobOpeningComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    MaterialModule,
    AuthModule
  ],
  providers: [AlisApiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
