<section id="booking">
    <div class="container pb-5">
        <div class="text-center page-title">
            <h1>Booking<br /></h1>
        </div>
        <mat-card class="mb-3">
            <p>Tired of waiting? Use our quick booking service!</p>
            <p><span class="font-weight-bold">Please note:</span> If you are late to your booking the next customer waiting will be served!</p>
        </mat-card>
        <!--Booking form component-->
        <app-booking-form></app-booking-form>
    </div>
</section>