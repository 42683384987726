<section id="gallery">
  <div class="container pb-5">
    <div class="text-center page-title">
      <h1>Gallery</h1>
    </div>
    <div class="mt-3">
      <div class="row">
        <div class="col-12 pl-lg-0">
          <a
            href="assets/images/gallery/mosh_trim.jpg"
            data-lightbox="image-1"
            data-title="My caption"
            ><img
              class="gallery-image-size mx-2 my-2"
              src="assets/images/gallery/mosh_trim.jpg"
          /></a>
          <a
            href="assets/images/gallery/mosh_ali.jpg"
            data-lightbox="image-1"
            data-title="My caption"
            ><img
              class="gallery-image-size mx-2 my-2"
              src="assets/images/gallery/mosh_ali.jpg"
          /></a>
          <a
            href="assets/images/gallery/mh_trim.jpg"
            data-lightbox="image-1"
            data-title="My caption"
            ><img
              class="gallery-image-size mx-2 my-2"
              src="assets/images/gallery/mh_trim.jpg"
          /></a>
          <a
            href="assets/images/gallery/rose_trim.jpg"
            data-lightbox="image-1"
            data-title="My caption"
            ><img
              class="gallery-image-size mx-2 my-2"
              src="assets/images/gallery/rose_trim.jpg"
          /></a>
          <a
            href="assets/images/gallery/wsh_trim.jpg"
            data-lightbox="image-1"
            data-title="My caption"
            ><img
              class="gallery-image-size mx-2 my-2"
              src="assets/images/gallery/wsh_trim.jpg"
          /></a>
          <a
            href="assets/images/gallery/wh_trim.jpg"
            data-lightbox="image-1"
            data-title="My caption"
            ><img
              class="gallery-image-size mx-2 my-2"
              src="assets/images/gallery/wh_trim.jpg"
          /></a>
          <a
            href="assets/images/gallery/baby_trim.jpg"
            data-lightbox="image-1"
            data-title="My caption"
            ><img
              class="gallery-image-size mx-2 my-2"
              src="assets/images/gallery/baby_trim.jpg"
          /></a>
          <a
            href="assets/images/gallery/pattern_1.jpg"
            data-lightbox="image-1"
            data-title="My caption"
            ><img
              class="gallery-image-size mx-2 my-2"
              src="assets/images/gallery/pattern_1.jpg"
          /></a>
          <a
            href="assets/images/gallery/pattern_2.jpg"
            data-lightbox="image-1"
            data-title="My caption"
            ><img
              class="gallery-image-size mx-2 my-2"
              src="assets/images/gallery/pattern_2.jpg"
          /></a>
          <a
            href="assets/images/gallery/fade_1.jpg"
            data-lightbox="image-1"
            data-title="My caption"
            ><img
              class="gallery-image-size mx-2 my-2"
              src="assets/images/gallery/fade_1.jpg"
          /></a>
        </div>
      </div>
    </div>
  </div>
</section>
