<form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" class="box p-4 mx-lg-0 mx-1 mb-3 rounded font-weight-bold">
    <!--Name input-->
    <div class="form-group">
        <label for="customerName">Name</label>
        <input id="customerName" name="customerName" class="form-control" [(ngModel)]="bookingModel.customerName"
            required #nameField="ngModel" (blur)="onBlur(nameField)"
            [class.field-error]="myForm.submitted && nameField.invalid" />
        <div *ngIf="attemptedBooking && nameField.invalid" class="alert alert-danger my-1 py-1">
            Enter a valid name
        </div>
    </div>
    <!--Contact number input-->
    <div class="form-group">
        <label for="customerContactNo">Contact Number</label>
        <input id="customerContactNo" name="customerContactNo" class="form-control"
            [(ngModel)]="bookingModel.customerContactNo" required #contactNoField="ngModel"
            [class.field-error]="myForm.submitted && contactNoField.invalid" maxlength="11" />
        <div *ngIf="attemptedBooking && contactNoField.invalid" class="alert alert-danger my-1 py-1">
            Enter a valid contact number
        </div>
    </div>
    <!--Select Branch dropdown list-->
    <div class="form-group">
        <label for="branchSelected">Select Branch</label>
        <select class="form-control" id="branchSelected" name="branchSelected" [(ngModel)]="bookingModel.branchSelected"
            required #branchDropDown="ngModel" [class.field-error]="myForm.submitted && branchDropDown.invalid"
            (ngModelChange)='filterBarberByBranch($event, myForm)'>
            <option [ngValue]=null disabled>Please Select a Branch</option>
            <!--whatever value selected ngvalue will be assigned to [(ngmodel)]-->
            <option *ngFor="let branch of branchLocation" [ngValue]="branch.id">
                {{ branch.branch_name }}
            </option>
        </select>
        <div *ngIf="attemptedBooking && branchDropDown.invalid" class="alert alert-danger my-1 py-1">
            Please select a branch for your booking
        </div>
    </div>
    <!--Select Barber dropdown list-->
    <div class="form-group">
        <label for="barberSelected">Select Barber</label>
        <select class="form-control" id="barberSelected" name="barberSelected" [(ngModel)]="bookingModel.barberSelected"
            required #barberDropDown="ngModel" [class.field-error]="myForm.submitted && barberDropDown.invalid"
            (ngModelChange)='selectBarber($event, myForm)'>
            <option [ngValue]=null disabled>Please Select a Barber</option>
            <option *ngFor="let barber of barbersList" [ngValue]="barber.id">
                {{barber.first_name}}
            </option>
        </select>
        <div *ngIf="attemptedBooking && barberDropDown.invalid" class="alert alert-danger my-1 py-1">
            Please select a barber for your booking
        </div>
    </div>
    <!--Select Date of booking-->
    <div class="form-group">
        <label for="dateSelected">Select a Date</label>
        <div class="input-group">
            <input id="dateSelected" name="dateSelected" class="form-control" [(ngModel)]="bookingModel.dateOfBooking"
                required #dateBookingField="ngModel" [class.field-error]="myForm.submitted && dateBookingField.invalid"
                type="text" placeholder="Please Select a Date" bsDatepicker [minDate]="minDate" [maxDate]="maxDate"
                [bsConfig]="{ adaptivePosition: true, containerClass: 'theme-red', dateInputFormat: 'MMMM Do YYYY', displayOneMonthRange: true }"
                (ngModelChange)='showTimesByDate($event, myForm)'>
            <div class="input-group-append">
                <label class="input-group-text" for="dateSelected"><i class="fa fa-calendar mx-auto"
                        aria-hidden="true"></i></label>
            </div>
        </div>
        <div *ngIf="attemptedBooking && dateBookingField.invalid" class="alert alert-danger my-1 py-1">
            Please select a valid date
        </div>
    </div>
    <!--Select Time of Booking-->
    <div class="form-group">
        <label for="timeOfBooking">Select An Available Time</label>
        <div class="input-group">
            <select class="form-control" id="timeOfBooking" name="timeOfBooking"
                [(ngModel)]="bookingModel.timeOfBooking" required #timeDropDown="ngModel"
                [class.field-error]="myForm.submitted && timeDropDown.invalid">
                <option [ngValue]=null disabled>Please Select a Time</option>
                <option *ngFor="let time of availableTimes">
                    {{time| date:'HH:mm a'}}
                </option>
            </select>
            <div class="input-group-append">
                <label class="input-group-text" for="timeOfBooking"><i class="fa fa-clock-o mx-auto"
                        aria-hidden="true"></i></label>
            </div>
        </div>
        <div *ngIf="attemptedBooking && timeDropDown.invalid" class="alert alert-danger my-1 py-1">
            Please select an available time
        </div>
    </div>
    <!--POST form errors on form-->
    <div [hidden]="!postError" class="alert alert-danger">
        {{ postErrorMessage }}
    </div>
    <!--Booking Button-->
    <ng-template>
        <button mat-flat-button type="submit" class="book-btn w-100 rounded text-white">
            Book Now
        </button>
    </ng-template>

    <button mat-flat-button type="button" (click)="confirmBookingModal(confirmBooking, myForm)"
        class="book-btn w-100 rounded text-white">
        Book Now
    </button>
</form>

<!--confirm booking modal-->
<ng-template #confirmBooking>
    <h4>Confirm your booking</h4>
    <div>
        <p><span class="font-weight-bold">Your Name:</span> {{bookingModel.customerName}}</p>
        <p><span class="font-weight-bold">Your Number:</span> {{bookingModel.customerContactNo}}</p>
        <p><span class="font-weight-bold">Branch:</span> {{bookingModel.branchSelected}}</p>
        <p><span class="font-weight-bold">Barber:</span> {{bookingModel.barberSelected}}</p>
        <p><span class="font-weight-bold">Date:</span> {{bookingModel.dateOfBooking | date}}</p>
        <p><span class="font-weight-bold">Time:</span> {{bookingModel.timeOfBooking}}</p>
    </div>
    <div class="d-flex justify-content-between">
        <button mat-flat-button mat-dialog-close type="button"
            class="confirm-cancel-btn w-100 rounded text-white btn-danger mr-2">
            Cancel
        </button>
        <button mat-flat-button mat-dialog-close type="submit"
            class="confirm-cancel-btn w-100 rounded text-white btn-success ml-2" (click)="onSubmit(myForm)">
            Confirm
        </button>
    </div>
</ng-template>

<!--successful booking modal-->
<ng-template #succesfulBooking>
    <h4 class="text-success">BOOKING SUCCESSFUL</h4>
    <p><span class="font-weight-bold">Note:</span> Please give your name and time of booking on your arrival.</p>
    <p>Thank you for booking with Ali's Barber Shop!</p>

    <div class="text-center">
        <button mat-flat-button mat-dialog-close class="confirm-cancel-btn w-100 rounded text-white btn-success">
            Close
        </button>
    </div>
</ng-template>

<!--failed booking modal-->
<ng-template #failedBooking>
    <h4 class="text-danger">BOOKING UNSUCCESSFUL</h4>
    <p>There seems to be an issue with your booking, please refresh the site and try again later.</p>
    <p>If this issue continues, please contact management at Ali's Barber Shop at 07777777 for bookings and we will
        escalate
        this as soon as possible.</p>
    <p>We apologise for any inconvenience caused!</p>
</ng-template>