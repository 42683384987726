<div class="container">
    <h2 class="font-weight-bold my-4 text-center">Booking Management</h2>

    <form #viewBookingForm="ngForm">
        <!--Select Branch dropdown list-->
        <div class="form-group">
            <label for="branchSelected" class="font-weight-bold">Select Branch</label>
            <select class="form-control" id="branchSelected" name="branchSelected"
                [(ngModel)]="bookingModel.branchSelected" required #branchDropDown="ngModel"
                [class.field-error]="viewBookingForm.submitted && branchDropDown.invalid"
                (ngModelChange)='filterBarberByBranch($event, viewBookingForm)'>>
                <option [ngValue]=null disabled>Please Select a Branch</option>
                <!--whatever value selected ngvalue will be assigned to [(ngmodel)]-->
                <option *ngFor="let branch of branchLocation" [ngValue]="branch.id">
                    {{ branch.branch_name }}
                </option>
            </select>
            <div [hidden]="!viewBookingForm.submitted || branchDropDown.valid" class="alert alert-danger my-1 py-1">
                Please select a branch for your booking
            </div>
        </div>
        <!--Select Barber dropdown list-->
        <div class="form-group">
            <label for="barberSelected" class="font-weight-bold">Select Barber</label>
            <select class="form-control" id="barberSelected" name="barberSelected"
                [(ngModel)]="bookingModel.barberSelected" required #barberDropDown="ngModel"
                [class.field-error]="viewBookingForm.submitted && barberDropDown.invalid"
                (ngModelChange)='selectBarber($event, viewBookingForm)'>
                <option [ngValue]=null disabled>Please Select a Barber</option>
                <option *ngFor="let barber of barbersList" [ngValue]="barber.id">
                    {{barber.first_name}} {{barber.barberId}}
                </option>
            </select>
            <div [hidden]="!viewBookingForm.submitted || barberDropDown.valid" class="alert alert-danger my-1 py-1">
                Please select a barber for your booking
            </div>
        </div>
        <!--Select Date of booking-->
        <div class="form-group">
            <label for="dateSelected" class="font-weight-bold">Select a Date</label>
            <div class="input-group">
                <input id="dateSelected" name="dateSelected" class="form-control"
                    [(ngModel)]="bookingModel.dateOfBooking" required #dateBookingField="ngModel"
                    [class.field-error]="viewBookingForm.submitted && dateBookingField.invalid" type="text"
                    placeholder="Please Select a Date" bsDatepicker
                    [bsConfig]="{ adaptivePosition: true, containerClass: 'theme-red', dateInputFormat: 'MMMM Do YYYY', displayOneMonthRange: true }"
                    (ngModelChange)='showTimesByDate($event, viewBookingForm)'>
                <div class="input-group-append">
                    <label class="input-group-text" for="dateSelected"><i class="fa fa-calendar mx-auto"
                            aria-hidden="true"></i></label>
                </div>
            </div>
            <div [hidden]="!viewBookingForm.submitted || dateBookingField.valid" class="alert alert-danger my-1 py-1">
                Please select a valid date
            </div>
        </div>
    </form>

    <!--Table of all bookings-->
    <div class="mat-elevation-z8" *ngIf="showTimesTable && bookingsOnSelectedDate?.length > 0; else elseBlock">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Time</th>
                    <th>Customer Name</th>
                    <th>Contact Number</th>
                </tr>
            <tbody>
                <tr *ngFor="let times of bookingsOnSelectedDate">
                    <td>{{times.booking_datetime | date:'HH:mm a' }}</td>
                    <td>{{times.customer_name}}</td>
                    <td>{{times.customer_contact_no}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--if nothing selected or no bookings on given date-->
    <ng-template #elseBlock>
        <h5 class="text-center">No Bookings</h5>
    </ng-template>
</div>