import { Component, OnInit, ViewChild } from '@angular/core';
import { BookingFormModel } from '../models/booking-form-model.model';
import { NgForm, NgModel } from '@angular/forms';
import { AlisApiService } from '../services/alis-api.service';
import { BranchModel } from '../models/branch-model.model';
import { BookingsDataModel } from '../models/bookings-data-model.model';
import { PostBookingsData } from '../models/post-bookings-data.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss']
})
export class BookingFormComponent implements OnInit {

  // original default null form object with null data
  originalBookingModel: BookingFormModel = {
    customerName: null,
    customerContactNo: null,
    branchSelected: null,
    barberSelected: null,
    dateOfBooking: null,
    timeOfBooking: null
  };

  // model object used when submitting to prevent error on original if cancelled
  bookingModel: BookingFormModel = { ...this.originalBookingModel };

  // orginal data posted
  originalPostBookingData: PostBookingsData = {
    barber: null,
    customer_name: null,
    customer_contact_no: null,
    booking_datetime: null
  }

  // model data used when posting
  postBookingData: PostBookingsData = { ...this.originalPostBookingData }

  // POST error variables
  postError = false;
  postErrorMessage = '';

  // dropdown data on form
  branchLocation: BranchModel[];
  barbersList = [];

  // date picker related variables
  minDate: Date;
  maxDate: Date;

  // times booked 
  timesBooked: BookingsDataModel[];
  // available times 
  availableTimes: Date[] = [];

  // barber start time
  barberStartTime: Date;

  // confirm value
  attemptedBooking: boolean = false;

  // success booking modal
  @ViewChild('succesfulBooking') succesRef;
  @ViewChild('failedBooking') failedRef;

  constructor(private apiService: AlisApiService, public dialog: MatDialog) {
    // date picker initalised, new date object created per min and max and dates set 7 day range
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate() + 7);
  }

  ngOnInit(): void {
    // call to api service to get list of branches and assign to branchLocation array
    this.apiService.getBranches().subscribe(
      branchList => {
        this.branchLocation = branchList
      }
    );
  }

  // method if required, when tabbing off an input of the form can add error as you go on etc
  onBlur(field: NgModel) {
    if (field.valid == false) {
      console.log('name field is : ', field.valid);
    }
    else {
      console.log('name field is : ', field.valid);
    }
  }

  // HTTP Error method on submit - POST booking to server
  onHttpError(errorResponse: any) {
    console.log('error when booking: ', errorResponse);
    this.postError = true;
    this.postErrorMessage = errorResponse.error.errorMessage
  }

  //function to filter barbers according to branch selected, passes id of branch to api call
  filterBarberByBranch(branchId: number, myForm: NgForm) {
    if (branchId !== null) {
      this.apiService.getBarbersByBranch(branchId).subscribe(
        barberData => {
          this.barbersList = barberData
        }
      );
      if (this.bookingModel.barberSelected !== null) {
        myForm.controls['barberSelected'].reset()
        myForm.controls['dateSelected'].reset()
        myForm.controls['timeOfBooking'].reset()
      }
    }
  }

  // function to reset date and tinme after barber selected and reset selected barber ID
  selectBarber(barberId: number, myForm: NgForm) {
    if (this.bookingModel.dateOfBooking === null) {
      for (let i = 0; i < this.barbersList.length; i++) {
        if (barberId === this.barbersList[i].id) {
          this.barberStartTime = this.barbersList[i].start_time
          break;
        }
      }
    }
    else {
      for (let i = 0; i < this.barbersList.length; i++) {
        if (barberId === this.barbersList[i].id) {
          this.barberStartTime = this.barbersList[i].start_time
          break;
        }
      }
      myForm.controls['dateSelected'].reset()
      myForm.controls['timeOfBooking'].reset()
      this.availableTimes = null;
    }
  }

  // function to filter times by date and barber selected
  showTimesByDate(dateSelected: Date, myForm: NgForm) {
    if (this.bookingModel.barberSelected != null && this.bookingModel.dateOfBooking != null) {
      // maybe change to let check after
      var barberId = this.bookingModel.barberSelected;
      this.apiService.getBookingsByBarber(barberId).subscribe(
        timeData => {
          this.timesBooked = timeData

          // array of all dates booked
          var allBookings = [];
          var bookingOnSelectedDate = [];
          let hours: number = Number(this.barberStartTime.toString().substr(0, 2));
          let minutes: number = Number(this.barberStartTime.toString().substr(3, 2));
          let startTime: Date = new Date(dateSelected.setHours(hours, minutes, 0, 0));
          let endTime: Date = new Date(dateSelected.setHours(20, 30, 0, 0))
          for (var i = 0; i < this.timesBooked.length; i++) {
            // all bookings converted into date objects
            allBookings.push(new Date(this.timesBooked[i].booking_datetime));
            if (allBookings[i] >= startTime && allBookings[i] <= endTime) {
              bookingOnSelectedDate.push(allBookings[i]);
            }
          }
          myForm.controls['timeOfBooking'].reset()
          this.findTimesAvailable(startTime, endTime, bookingOnSelectedDate);
        }
      );
    }
  }

  // method to find times available 
  findTimesAvailable(startTime: Date, endTime: Date, bookingOnSelectedDate: Date[]) {
    let allTimes: Date[] = [];
    let tempSlots = new Date();
    tempSlots = startTime;
    // first postion set to start 
    allTimes.push(new Date(tempSlots));
    while (tempSlots.getTime() != endTime.getTime()) {
      tempSlots.setTime(tempSlots.getTime() + (30 * 60 * 1000));
      allTimes.push(new Date(tempSlots));
    }

    let timesAvailable: Date[] = [];

    for (var x = 0; x < allTimes.length; x++) {
      // we want to know if a[i] is found in b
      var match = false; // we haven't found it yet
      for (var y = 0; y < bookingOnSelectedDate.length; y++) {
        if (allTimes[x].getTime() == bookingOnSelectedDate[y].getTime()) {
          // we have found a[i] in b, so we can stop searching
          match = true;
          break;
        }
        // if we never find a[i] in b, the for loop will simply end,
        // and match will remain false
      }
      // add a[i] to newArray only if we didn't find a match.
      if (!match) {
        timesAvailable.push(allTimes[x]);
      }
    }
    // times available dropdown updated
    this.availableTimes = timesAvailable;
  }

  // submit button on form, validation check and post data to server
  onSubmit(myForm: NgForm) {
    if (myForm.valid) {
      this.postBookingData.barber = this.bookingModel.barberSelected;
      this.postBookingData.customer_name = this.bookingModel.customerName;
      this.postBookingData.customer_contact_no = this.bookingModel.customerContactNo;

      let hourSelected = Number(this.bookingModel.timeOfBooking.toString().substr(0, 2));
      let minSelected = Number(this.bookingModel.timeOfBooking.toString().substr(3, 2));
      this.bookingModel.dateOfBooking.setHours(hourSelected, minSelected, 0, 0);
      this.postBookingData.booking_datetime = this.bookingModel.dateOfBooking;

      // call method from api service to post data
      this.apiService.postBookingFormData(this.postBookingData).subscribe(
        result => {
          console.log('successful booking: ', result)
          this.bookingStatus(this.succesRef);
        },
        // call onHttpError method if there is an HTTP error
        error => {
          this.onHttpError(error)
          this.bookingStatus(this.failedRef);

        }
      );
      myForm.resetForm();
      this.barbersList = null;
      this.availableTimes = null;

      this.postError = false;
      this.postErrorMessage = '';
    }
    else {
      // if form is invalid will display the below 
      this.postError = true;
      this.postErrorMessage = 'Please fill in the empty fields to complete your boooking!';
    }
  }

  // confirmation open modal
  confirmBookingModal(templateRef, myForm: NgForm) {
    this.attemptedBooking = true;
    if (myForm.valid) {
      this.attemptedBooking = false;
      let dialogRef = this.dialog.open(templateRef, {
        // width: '250px',
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });

    } else {
      // if form is invalid will display the below 
      this.postError = true;
      this.postErrorMessage = 'Please fill in the empty fields to complete your boooking!';
    }
  }

  // modal for successful and failed bookings when POST is made
  bookingStatus(templateRef) {
    let dialogRef = this.dialog.open(templateRef, {
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}