import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BranchModel } from '../models/branch-model.model';
import { PostBookingsData } from '../models/post-bookings-data.model';
import { BookingsDataModel } from '../models/bookings-data-model.model';
import { AllBookingsDataModel } from '../models/all-bookings-model.model';


@Injectable({
  providedIn: 'root'
})
export class AlisApiService {

  constructor(private http: HttpClient) { }

  // token passed in the header for the http requests
  header = new HttpHeaders().set(
    "Authorization",
    "Token 170288b07e930b96bef02d902c53e2381a1616dc"
  );

  private baseApiUrl = "https://api.devalyticx.co.uk";

  // get list of ALL branches via API
  getBranches(): Observable<BranchModel[]> {
    return this.http.get<BranchModel[]>(this.baseApiUrl + '/branches/', { headers: this.header });
  }

  // get list of ALL barbers via API
  getBarbers(): Observable<any> {
    return this.http.get(this.baseApiUrl + '/barbers/', { headers: this.header });
  }

  // get list of barbers by branch ID via API
  getBarbersByBranch(id: number): Observable<any> {
    return this.http.get(this.baseApiUrl + '/barbers/?branch=' + id, { headers: this.header });
  }

  // get all bookings call, list of all bookings via API
  getBookings(): Observable<AllBookingsDataModel[]> {
    return this.http.get<AllBookingsDataModel[]>(this.baseApiUrl + '/bookings/', { headers: this.header });
  }

  // get booking date and time of bookings by barber ID
  getBookingsByBarber(id: number): Observable<BookingsDataModel[]> {
    return this.http.get<BookingsDataModel[]>(this.baseApiUrl + '/bookings/?barber=' + id, { headers: this.header });
  }

  // method to post booking form data to DB, returns observable of type any to handle whatever post returns
  postBookingFormData(postBookingsData: PostBookingsData): Observable<any> {
    // HTTP client works with observables so can return this post
    return this.http.post(this.baseApiUrl + '/bookings/', postBookingsData, { headers: this.header });

    // of function is one way to create an observable from rxjs, acts as fake observable to test
    // return of(bookingData);
  }
}
