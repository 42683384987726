import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { ManagementLoginComponent } from './components/management-login/management-login.component';
import { MaterialModule } from '../modules/material/material.module';


@NgModule({
  declarations: [ManagementLoginComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    MaterialModule
  ],
  // add exports array so anyone using this module can give access to the components as followed
  exports: [
    ManagementLoginComponent
  ]
})
export class AuthModule { }
