<mat-sidenav-container fullscreen>
  <!-- The side nav -->
  <mat-sidenav #drawer fixedInViewport="true" mode="push" [opened]="false">
    <mat-toolbar
      ><img class="logo-on-nav" src="assets/images/logos/main_logo.png"
    /></mat-toolbar>
    <mat-nav-list (click)="drawer.close()"> </mat-nav-list>
  </mat-sidenav>
  <!-- EO: Side nav-->

  <!-- Side nav content-->
  <mat-sidenav-content>
    <mat-toolbar>
      <a [routerLink]="['']"
        ><img
          class="logo-on-nav rounded-circle"
          style="margin-right: 10px"
          src="assets/images/logos/main_logo.png"
        />
      </a>
      <h3 class="text-center font-weight-bold" style="margin: 0">
        Ali's Barber Shop & Beauty Salon
      </h3>
      <span class="mat-spacer"></span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
  <!-- EO: Side nav content-->
</mat-sidenav-container>
