import { Component, OnInit } from '@angular/core';
import { AlisApiService } from '../services/alis-api.service';
import { BranchModel } from '../models/branch-model.model';
import { PostBookingsData } from '../models/post-bookings-data.model';
import { BookingFormModel } from '../models/booking-form-model.model';
import { NgForm } from '@angular/forms';
import { BookingsDataModel } from '../models/bookings-data-model.model';
import { MatTableDataSource } from '@angular/material/table';
import { AllBookingsDataModel } from '../models/all-bookings-model.model';

@Component({
  selector: 'app-management-view-bookings-page',
  templateUrl: './management-view-bookings-page.component.html',
  styleUrls: ['./management-view-bookings-page.component.scss']
})

export class ManagementViewBookingsPageComponent implements OnInit {

  // original default null form object with null data
  originalBookingModel: BookingFormModel = {
    customerName: null,
    customerContactNo: null,
    branchSelected: null,
    barberSelected: null,
    dateOfBooking: null,
    timeOfBooking: null
  };

  // model object used when submitting to prevent error on original if cancelled
  bookingModel: BookingFormModel = { ...this.originalBookingModel };

  branchLocation: BranchModel[];
  barbersList = [];
  barberStartTime: Date;
  showTimesTable: boolean = false;
  allBookingsOfBarber: AllBookingsDataModel[] = [];
  bookingsOnSelectedDate: AllBookingsDataModel[] = [];

  constructor(private apiService: AlisApiService) { }

  ngOnInit(): void {
    // call to api service to get list of branches and assign to branchLocation array
    this.apiService.getBranches().subscribe(
      branchList => {
        this.branchLocation = branchList
      }
    );
  }

  //function to filter barbers according to branch selected, passes id of branch to api call
  filterBarberByBranch(branchId: number, myForm: NgForm) {
    if (branchId !== null) {
      this.apiService.getBarbersByBranch(branchId).subscribe(
        barberData => {
          this.barbersList = barberData
        }
      );
      if (this.bookingModel.barberSelected !== null) {
        myForm.controls['barberSelected'].reset()
        myForm.controls['dateSelected'].reset()
      }
    }
    this.showTimesTable = false;
  }

  // function to reset date and tinme after barber selected and reset selected barber ID
  selectBarber(barberId: number, myForm: NgForm) {
    if (this.bookingModel.dateOfBooking === null) {
      for (let i = 0; i < this.barbersList.length; i++) {
        if (barberId === this.barbersList[i].id) {
          this.barberStartTime = this.barbersList[i].start_time
          break;
        }
      }
    }
    else {
      for (let i = 0; i < this.barbersList.length; i++) {
        if (barberId === this.barbersList[i].id) {
          this.barberStartTime = this.barbersList[i].start_time
          break;
        }
      }
      myForm.controls['dateSelected'].reset()
    }
    this.showTimesTable = false;
  }

  // function to filter times by date and barber selected
  showTimesByDate(dateSelected: Date, myForm: NgForm) {
    this.bookingsOnSelectedDate = [];
    if (this.bookingModel.barberSelected != null && this.bookingModel.dateOfBooking != null) {
      var barberId = this.bookingModel.barberSelected;
      this.apiService.getBookings().subscribe(
        data => {
          this.allBookingsOfBarber = data;
          // start time and end time of bookings
          let hours: number = Number(this.barberStartTime.toString().substr(0, 2));
          let minutes: number = Number(this.barberStartTime.toString().substr(3, 2));
          let startTime: Date = new Date(dateSelected.setHours(hours, minutes, 0, 0));
          let endTime: Date = new Date(dateSelected.setHours(20, 30, 0, 0))
          // filling bookings on selected date array
          this.allBookingsOfBarber.forEach((singleBooking) => {
            singleBooking.booking_datetime = new Date(singleBooking.booking_datetime);
            if (singleBooking.booking_datetime >= startTime && singleBooking.booking_datetime <= endTime) {
              this.bookingsOnSelectedDate.push(singleBooking);
            }
          })
          // sort bookings on selected date by time
          this.bookingsOnSelectedDate.sort((a: AllBookingsDataModel, b: AllBookingsDataModel) => {
            return a.booking_datetime.getTime() - b.booking_datetime.getTime();
          });
        }
      )
      this.showTimesTable = true;
    }
  }
}
