<footer class="footer bg-dark text-white pt-5 pb-3">
  <div class="container">
    <div class="row">
      <div class="col-md-4 text-center">
        <h4>Contact Us</h4>
        <p>258 Bethnal Green Road, London</p>
        <p>Email: alisbarbershop4@gmail.com</p>
        <p>Phone: 020 7613 3873</p>
      </div>
      <div class="col-md-4 text-center">
        <h4>Follow Us</h4>
        <a
          href="https://www.instagram.com/alis.barbershop/"
          class="text-white mx-2"
          target="_blank"
          ><i class="fa fa-instagram"></i
        ></a>
        <a
          href="https://m.facebook.com/Alis-Barber-shop-127753709063/"
          class="text-white mx-2"
          target="_blank"
          ><i class="fa fa-facebook"></i
        ></a>
      </div>
      <div class="col-md-4 text-center">
        <h4>Opening Hours</h4>
        <p>Mon - Sun: 10am - 8pm</p>
      </div>
    </div>
    <div class="text-center mt-4">
      <p class="mb-0">
        © 2024 Ali's Barber Shop & Beauty Salon. All rights reserved.
      </p>
    </div>
  </div>
</footer>
