<!-- Job Openings Section -->
<section class="job-openings text-center" style="background-color: #c52f3a">
  <div class="container">
    <h4 class="font-weight-bold text-black">
      Job Opening: Beauty Salon Manager
    </h4>
    <p class="mt-3 text-white">
      We are looking for a full-time Beauty Salon Manager to join our team.
    </p>
    <p class="text-white">
      Responsibilities include overseeing daily salon operations, managing
      staff, and ensuring customer satisfaction.
    </p>
    <p class="text-white">
      If you are passionate about beauty and have management experience, we want
      to hear from you!
    </p>
    <h4 class="mt-4">How to Apply:</h4>
    <p class="text-white">
      Please send your CV to
      <a href="mailto:info@alibarbershop.com">alisbarbershop4@gmail.com</a>
      or call us at <strong style="color: #f0b70c">020 7613 3873 </strong>.
    </p>
  </div>
</section>
