<section id="follow-us">
    <div class="container">
        <div class="text-center page-title">
            <h1>Follow Us</h1>
        </div>
        <div class="row text-center">
            <div class="col-lg-6 col-12">
                <a href="https://www.instagram.com/alis.barbershop/" target="_blank" style="text-decoration: none;">
                    <mat-card class="px-4 py-2 mx-lg-0 mx-1 mb-lg-5 mb-3 rounded">
                        <span class="fa fa-instagram" aria-hidden="true"></span>
                        <p class="font-weight-bold my-3">
                            Follow Us on Instagram!
                        </p>
                        <i>@alis.barbershop</i>
                    </mat-card>
                </a>
            </div>
            <div class="col-lg-6 col-12">
                <a href="https://m.facebook.com/Alis-Barber-shop-127753709063/" target="_blank"
                    style="text-decoration: none;">
                    <mat-card class="px-4 py-2 mx-lg-0 mx-1 mb-lg-5 mb-3 rounded">
                        <span class="fa fa-facebook-official" aria-hidden="true"></span>
                        <p class="font-weight-bold my-3">
                            Follow Us on Facebook!
                        </p>
                        <i>'Ali's Barber Shop'</i>
                    </mat-card>
                </a>
            </div>
        </div>
    </div>
</section>