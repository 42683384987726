<mat-sidenav-container>
    <!-- The side nav -->
    <mat-sidenav #drawer fixedInViewport="true" mode="push" [opened]="false">
      <mat-toolbar>Menu</mat-toolbar>
      <mat-nav-list (click)="drawer.close()">
        <a mat-list-item href="#home"><span class="fa fa-home mx-auto" aria-hidden="true"
            (click)="drawer.close()"></span></a>
        <a mat-list-item href="#booking"><span class="fa fa-calendar mx-auto" aria-hidden="true"
            (click)="drawer.close()"></span></a>
        <a mat-list-item href="#gallery"><span class="fa fa-camera mx-auto" aria-hidden="true"
            (click)="drawer.close()"></span></a>
        <a mat-list-item href="#find-us"><span class="fa fa-map-marker mx-auto" aria-hidden="true"
            (click)="drawer.close()"></span></a>
        <a mat-list-item href="https://www.instagram.com/alis.barbershop/" target="_blank"><span
            class="fa fa-instagram mx-auto" aria-hidden="true" (click)="drawer.close()"></span></a>
      </mat-nav-list>
    </mat-sidenav>
    <!-- EO: Side nav-->
  
    <!-- Side nav content-->
    <mat-sidenav-content>
      <mat-toolbar class="position-fixed">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span class="mat-spacer"></span>
        <a href="#home">
          <span class="logo-title">Ali's Barber Shop</span>
          <img class="logo-on-nav" src="assets/images/logos/main_logo.png">
        </a>
      </mat-toolbar>
      <div class="content">
        <app-home-page></app-home-page>
        <app-gallery-page></app-gallery-page>
        <app-booking-page></app-booking-page>
        <app-find-us-page></app-find-us-page>
        <app-follow-us-page></app-follow-us-page>
      </div>
    </mat-sidenav-content>
    <!-- EO: Side nav content-->
  </mat-sidenav-container>